/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrderByVenue = /* GraphQL */ `
  subscription OnCreateOrderByVenue($venueID: ID!) {
    onCreateOrderByVenue(venueID: $venueID) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateOrderByCustomer = /* GraphQL */ `
  subscription OnUpdateOrderByCustomer($customerID: ID!) {
    onUpdateOrderByCustomer(customerID: $customerID) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateOrderByVenue = /* GraphQL */ `
  subscription OnUpdateOrderByVenue($venueID: ID!) {
    onUpdateOrderByVenue(venueID: $venueID) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateReservationByVenue = /* GraphQL */ `
  subscription OnCreateReservationByVenue($venueID: ID!) {
    onCreateReservationByVenue(venueID: $venueID) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateReservationByCustomer = /* GraphQL */ `
  subscription OnUpdateReservationByCustomer($customerID: ID!) {
    onUpdateReservationByCustomer(customerID: $customerID) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateReservationByVenue = /* GraphQL */ `
  subscription OnUpdateReservationByVenue($venueID: ID!) {
    onUpdateReservationByVenue(venueID: $venueID) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateBookingByBusiness = /* GraphQL */ `
  subscription OnCreateBookingByBusiness($businessID: ID!) {
    onCreateBookingByBusiness(businessID: $businessID) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const onUpdateBookingByBusiness = /* GraphQL */ `
  subscription OnUpdateBookingByBusiness($businessID: ID!) {
    onUpdateBookingByBusiness(businessID: $businessID) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const onUpdateFoodsByVenue = /* GraphQL */ `
  subscription OnUpdateFoodsByVenue($venueID: ID!) {
    onUpdateFoodsByVenue(venueID: $venueID) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateTimeSlotByVenue = /* GraphQL */ `
  subscription OnUpdateTimeSlotByVenue($venueID: ID!) {
    onUpdateTimeSlotByVenue(venueID: $venueID) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReportByVenue = /* GraphQL */ `
  subscription OnCreateReportByVenue($venueID: ID!) {
    onCreateReportByVenue(venueID: $venueID) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($userID: String) {
    onCreateCustomer(userID: $userID) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($userID: String) {
    onUpdateCustomer(userID: $userID) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer($userID: String) {
    onDeleteCustomer(userID: $userID) {
      id
      userID
      universityID
      studentID
      cartID
      cbordPaymentToken
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      readGroups
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      cart {
        id
        owner
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
    }
  }
`;
export const onCreateDeliverer = /* GraphQL */ `
  subscription OnCreateDeliverer($userID: String) {
    onCreateDeliverer(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateDeliverer = /* GraphQL */ `
  subscription OnUpdateDeliverer($userID: String) {
    onUpdateDeliverer(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteDeliverer = /* GraphQL */ `
  subscription OnDeleteDeliverer($userID: String) {
    onDeleteDeliverer(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      orders {
        items {
          id
          owner
          customerID
          venueID
          eventID
          delivererID
          price
          totalPrice
          status
          notes
          createdAt
          timeSlot
          timeSlotID
          paymentMethod
          chargeID
          serviceOption
          locationName
          _version
          _deleted
          _lastChangedAt
          updatedAt
          customer {
            id
            userID
            universityID
            studentID
            cartID
            cbordPaymentToken
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            readGroups
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            cart {
              id
              owner
              content
              updatedAt
              _version
              _deleted
              _lastChangedAt
              createdAt
            }
          }
          history {
            items {
              id
              owner
              orderID
              status
              createdAt
              _version
              _deleted
              _lastChangedAt
              updatedAt
            }
            nextToken
            startedAt
          }
          orderItems {
            items {
              id
              owner
              orderID
              foodID
              price
              amount
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateDirector = /* GraphQL */ `
  subscription OnCreateDirector($userID: String) {
    onCreateDirector(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateDirector = /* GraphQL */ `
  subscription OnUpdateDirector($userID: String) {
    onUpdateDirector(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteDirector = /* GraphQL */ `
  subscription OnDeleteDirector($userID: String) {
    onDeleteDirector(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateProvider = /* GraphQL */ `
  subscription OnCreateProvider($userID: String) {
    onCreateProvider(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateProvider = /* GraphQL */ `
  subscription OnUpdateProvider($userID: String) {
    onUpdateProvider(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteProvider = /* GraphQL */ `
  subscription OnDeleteProvider($userID: String) {
    onDeleteProvider(userID: $userID) {
      id
      userID
      universityID
      firstName
      lastName
      phoneNumber
      schoolMajor
      schoolYear
      schoolOrganizations
      links
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateVendor = /* GraphQL */ `
  subscription OnCreateVendor($userID: String) {
    onCreateVendor(userID: $userID) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateVendor = /* GraphQL */ `
  subscription OnUpdateVendor($userID: String) {
    onUpdateVendor(userID: $userID) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteVendor = /* GraphQL */ `
  subscription OnDeleteVendor($userID: String) {
    onDeleteVendor(userID: $userID) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateStaff = /* GraphQL */ `
  subscription OnCreateStaff($userID: String) {
    onCreateStaff(userID: $userID) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateStaff = /* GraphQL */ `
  subscription OnUpdateStaff($userID: String) {
    onUpdateStaff(userID: $userID) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteStaff = /* GraphQL */ `
  subscription OnDeleteStaff($userID: String) {
    onDeleteStaff(userID: $userID) {
      id
      userID
      venueID
      universityID
      firstName
      lastName
      phoneNumber
      image_url
      image {
        bucket
        region
        key
      }
      status
      invitationalEmail
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      parentUniversity {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
      parentVenue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateCart = /* GraphQL */ `
  subscription OnCreateCart($owner: String) {
    onCreateCart(owner: $owner) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const onUpdateCart = /* GraphQL */ `
  subscription OnUpdateCart($owner: String) {
    onUpdateCart(owner: $owner) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const onDeleteCart = /* GraphQL */ `
  subscription OnDeleteCart($owner: String) {
    onDeleteCart(owner: $owner) {
      id
      owner
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const onCreateReservation = /* GraphQL */ `
  subscription OnCreateReservation($owner: String) {
    onCreateReservation(owner: $owner) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateReservation = /* GraphQL */ `
  subscription OnUpdateReservation($owner: String) {
    onUpdateReservation(owner: $owner) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteReservation = /* GraphQL */ `
  subscription OnDeleteReservation($owner: String) {
    onDeleteReservation(owner: $owner) {
      id
      owner
      customerID
      venueID
      eventID
      status
      createdAt
      timeSlot
      timeSlotID
      serviceOption
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateOrderState = /* GraphQL */ `
  subscription OnCreateOrderState($owner: String) {
    onCreateOrderState(owner: $owner) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onUpdateOrderState = /* GraphQL */ `
  subscription OnUpdateOrderState($owner: String) {
    onUpdateOrderState(owner: $owner) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onDeleteOrderState = /* GraphQL */ `
  subscription OnDeleteOrderState($owner: String) {
    onDeleteOrderState(owner: $owner) {
      id
      owner
      orderID
      status
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($owner: String) {
    onCreateOrder(owner: $owner) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($owner: String) {
    onUpdateOrder(owner: $owner) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($owner: String) {
    onDeleteOrder(owner: $owner) {
      id
      owner
      customerID
      venueID
      eventID
      delivererID
      price
      totalPrice
      status
      notes
      createdAt
      timeSlot
      timeSlotID
      paymentMethod
      chargeID
      serviceOption
      locationName
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      history {
        items {
          id
          owner
          orderID
          status
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      orderItems {
        items {
          id
          owner
          orderID
          foodID
          price
          amount
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venue {
        id
        universityID
        owner
        name
        businessName
        description
        status
        type
        categoty
        buffetPrice
        image_url
        image {
          bucket
          region
          key
        }
        payInApp
        payInPerson
        payWithCard
        payWithCash
        payWithStudentID
        payWithCurrency
        currency
        dineInAvailable
        reservationAvailable
        pickUpAvailable
        deliveryAvailable
        timeSlotsDineInCapacity
        timeSlotsPickUpCapacity
        timeSlotsDeliveryCapacity
        timeslotsIgnore
        timeslotsInterval
        timeSlotsStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        vendors {
          items {
            id
            userID
            venueID
            universityID
            firstName
            lastName
            phoneNumber
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            parentUniversity {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            parentVenue {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        events {
          items {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        reports {
          items {
            id
            owner
            venueID
            reportUri
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateOrderItem = /* GraphQL */ `
  subscription OnCreateOrderItem($owner: String) {
    onCreateOrderItem(owner: $owner) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateOrderItem = /* GraphQL */ `
  subscription OnUpdateOrderItem($owner: String) {
    onUpdateOrderItem(owner: $owner) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteOrderItem = /* GraphQL */ `
  subscription OnDeleteOrderItem($owner: String) {
    onDeleteOrderItem(owner: $owner) {
      id
      owner
      orderID
      foodID
      price
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateUniversity = /* GraphQL */ `
  subscription OnCreateUniversity($owner: String) {
    onCreateUniversity(owner: $owner) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateUniversity = /* GraphQL */ `
  subscription OnUpdateUniversity($owner: String) {
    onUpdateUniversity(owner: $owner) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteUniversity = /* GraphQL */ `
  subscription OnDeleteUniversity($owner: String) {
    onDeleteUniversity(owner: $owner) {
      id
      owner
      name
      description
      domain
      cbordUniversity
      cbordEnabled
      utcOffset
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      businesses {
        items {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      venues {
        items {
          id
          universityID
          owner
          name
          businessName
          description
          status
          type
          categoty
          buffetPrice
          image_url
          image {
            bucket
            region
            key
          }
          payInApp
          payInPerson
          payWithCard
          payWithCash
          payWithStudentID
          payWithCurrency
          currency
          dineInAvailable
          reservationAvailable
          pickUpAvailable
          deliveryAvailable
          timeSlotsDineInCapacity
          timeSlotsPickUpCapacity
          timeSlotsDeliveryCapacity
          timeslotsIgnore
          timeslotsInterval
          timeSlotsStatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          vendors {
            items {
              id
              userID
              venueID
              universityID
              firstName
              lastName
              phoneNumber
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          events {
            items {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          reports {
            items {
              id
              owner
              venueID
              reportUri
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateBusiness = /* GraphQL */ `
  subscription OnCreateBusiness($owner: String) {
    onCreateBusiness(owner: $owner) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateBusiness = /* GraphQL */ `
  subscription OnUpdateBusiness($owner: String) {
    onUpdateBusiness(owner: $owner) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteBusiness = /* GraphQL */ `
  subscription OnDeleteBusiness($owner: String) {
    onDeleteBusiness(owner: $owner) {
      id
      universityID
      providerID
      owner
      name
      description
      locationOptionOnSite
      locationOptionVisit
      shortLocation
      telephone
      email
      links
      status
      image_url
      image {
        bucket
        region
        key
      }
      categories
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      provider {
        id
        userID
        universityID
        firstName
        lastName
        phoneNumber
        schoolMajor
        schoolYear
        schoolOrganizations
        links
        image_url
        image {
          bucket
          region
          key
        }
        status
        invitationalEmail
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      university {
        id
        owner
        name
        description
        domain
        cbordUniversity
        cbordEnabled
        utcOffset
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        businesses {
          items {
            id
            universityID
            providerID
            owner
            name
            description
            locationOptionOnSite
            locationOptionVisit
            shortLocation
            telephone
            email
            links
            status
            image_url
            image {
              bucket
              region
              key
            }
            categories
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            provider {
              id
              userID
              universityID
              firstName
              lastName
              phoneNumber
              schoolMajor
              schoolYear
              schoolOrganizations
              links
              image_url
              status
              invitationalEmail
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        venues {
          items {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateVenue = /* GraphQL */ `
  subscription OnCreateVenue($owner: String) {
    onCreateVenue(owner: $owner) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateVenue = /* GraphQL */ `
  subscription OnUpdateVenue($owner: String) {
    onUpdateVenue(owner: $owner) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteVenue = /* GraphQL */ `
  subscription OnDeleteVenue($owner: String) {
    onDeleteVenue(owner: $owner) {
      id
      universityID
      owner
      name
      businessName
      description
      status
      type
      categoty
      buffetPrice
      image_url
      image {
        bucket
        region
        key
      }
      payInApp
      payInPerson
      payWithCard
      payWithCash
      payWithStudentID
      payWithCurrency
      currency
      dineInAvailable
      reservationAvailable
      pickUpAvailable
      deliveryAvailable
      timeSlotsDineInCapacity
      timeSlotsPickUpCapacity
      timeSlotsDeliveryCapacity
      timeslotsIgnore
      timeslotsInterval
      timeSlotsStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      vendors {
        items {
          id
          userID
          venueID
          universityID
          firstName
          lastName
          phoneNumber
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          parentUniversity {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          parentVenue {
            id
            universityID
            owner
            name
            businessName
            description
            status
            type
            categoty
            buffetPrice
            image_url
            image {
              bucket
              region
              key
            }
            payInApp
            payInPerson
            payWithCard
            payWithCash
            payWithStudentID
            payWithCurrency
            currency
            dineInAvailable
            reservationAvailable
            pickUpAvailable
            deliveryAvailable
            timeSlotsDineInCapacity
            timeSlotsPickUpCapacity
            timeSlotsDeliveryCapacity
            timeslotsIgnore
            timeslotsInterval
            timeSlotsStatus
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            vendors {
              nextToken
              startedAt
            }
            events {
              nextToken
              startedAt
            }
            reports {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      events {
        items {
          id
          owner
          venueID
          name
          description
          active
          recurrenceFrequency
          recurrenceInterval
          recurrenceCount
          recurrentDays
          startDate
          endDate
          startTime
          endTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          exceptions {
            items {
              id
              owner
              eventID
              name
              description
              type
              date
              startTime
              endTime
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          timeSlots {
            items {
              id
              owner
              venueID
              eventID
              type
              capacity
              position
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      reports {
        items {
          id
          owner
          venueID
          reportUri
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($owner: String) {
    onCreateEvent(owner: $owner) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($owner: String) {
    onUpdateEvent(owner: $owner) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($owner: String) {
    onDeleteEvent(owner: $owner) {
      id
      owner
      venueID
      name
      description
      active
      recurrenceFrequency
      recurrenceInterval
      recurrenceCount
      recurrentDays
      startDate
      endDate
      startTime
      endTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          eventID
          name
          description
          type
          date
          startTime
          endTime
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menus {
            items {
              id
              owner
              eventID
              menuID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      timeSlots {
        items {
          id
          owner
          venueID
          eventID
          type
          capacity
          position
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateEventException = /* GraphQL */ `
  subscription OnCreateEventException($owner: String) {
    onCreateEventException(owner: $owner) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateEventException = /* GraphQL */ `
  subscription OnUpdateEventException($owner: String) {
    onUpdateEventException(owner: $owner) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteEventException = /* GraphQL */ `
  subscription OnDeleteEventException($owner: String) {
    onDeleteEventException(owner: $owner) {
      id
      owner
      eventID
      name
      description
      type
      date
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateEventMenu = /* GraphQL */ `
  subscription OnCreateEventMenu($owner: String) {
    onCreateEventMenu(owner: $owner) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateEventMenu = /* GraphQL */ `
  subscription OnUpdateEventMenu($owner: String) {
    onUpdateEventMenu(owner: $owner) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteEventMenu = /* GraphQL */ `
  subscription OnDeleteEventMenu($owner: String) {
    onDeleteEventMenu(owner: $owner) {
      id
      owner
      eventID
      menuID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      event {
        id
        owner
        venueID
        name
        description
        active
        recurrenceFrequency
        recurrenceInterval
        recurrenceCount
        recurrentDays
        startDate
        endDate
        startTime
        endTime
        duration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exceptions {
          items {
            id
            owner
            eventID
            name
            description
            type
            date
            startTime
            endTime
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        timeSlots {
          items {
            id
            owner
            venueID
            eventID
            type
            capacity
            position
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateMenu = /* GraphQL */ `
  subscription OnCreateMenu($owner: String) {
    onCreateMenu(owner: $owner) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateMenu = /* GraphQL */ `
  subscription OnUpdateMenu($owner: String) {
    onUpdateMenu(owner: $owner) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteMenu = /* GraphQL */ `
  subscription OnDeleteMenu($owner: String) {
    onDeleteMenu(owner: $owner) {
      id
      owner
      venueID
      name
      description
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        items {
          id
          owner
          eventID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          event {
            id
            owner
            venueID
            name
            description
            active
            recurrenceFrequency
            recurrenceInterval
            recurrenceCount
            recurrentDays
            startDate
            endDate
            startTime
            endTime
            duration
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            exceptions {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            timeSlots {
              nextToken
              startedAt
            }
          }
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      foods {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateMenuFood = /* GraphQL */ `
  subscription OnCreateMenuFood($owner: String) {
    onCreateMenuFood(owner: $owner) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateMenuFood = /* GraphQL */ `
  subscription OnUpdateMenuFood($owner: String) {
    onUpdateMenuFood(owner: $owner) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeleteMenuFood = /* GraphQL */ `
  subscription OnDeleteMenuFood($owner: String) {
    onDeleteMenuFood(owner: $owner) {
      id
      owner
      menuID
      foodID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menu {
        id
        owner
        venueID
        name
        description
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        events {
          items {
            id
            owner
            eventID
            menuID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            event {
              id
              owner
              venueID
              name
              description
              active
              recurrenceFrequency
              recurrenceInterval
              recurrenceCount
              recurrentDays
              startDate
              endDate
              startTime
              endTime
              duration
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
        foods {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
      food {
        id
        owner
        venueID
        name
        description
        price
        active
        calories
        foodType
        image_url
        image {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        menus {
          items {
            id
            owner
            menuID
            foodID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menu {
              id
              owner
              venueID
              name
              description
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            food {
              id
              owner
              venueID
              name
              description
              price
              active
              calories
              foodType
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateFood = /* GraphQL */ `
  subscription OnCreateFood($owner: String) {
    onCreateFood(owner: $owner) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateFood = /* GraphQL */ `
  subscription OnUpdateFood($owner: String) {
    onUpdateFood(owner: $owner) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteFood = /* GraphQL */ `
  subscription OnDeleteFood($owner: String) {
    onDeleteFood(owner: $owner) {
      id
      owner
      venueID
      name
      description
      price
      active
      calories
      foodType
      image_url
      image {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      menus {
        items {
          id
          owner
          menuID
          foodID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          menu {
            id
            owner
            venueID
            name
            description
            active
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            events {
              nextToken
              startedAt
            }
            foods {
              nextToken
              startedAt
            }
          }
          food {
            id
            owner
            venueID
            name
            description
            price
            active
            calories
            foodType
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            menus {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateTimeSlot = /* GraphQL */ `
  subscription OnCreateTimeSlot($owner: String) {
    onCreateTimeSlot(owner: $owner) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTimeSlot = /* GraphQL */ `
  subscription OnUpdateTimeSlot($owner: String) {
    onUpdateTimeSlot(owner: $owner) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTimeSlot = /* GraphQL */ `
  subscription OnDeleteTimeSlot($owner: String) {
    onDeleteTimeSlot(owner: $owner) {
      id
      owner
      venueID
      eventID
      type
      capacity
      position
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($owner: String) {
    onCreateReport(owner: $owner) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($owner: String) {
    onUpdateReport(owner: $owner) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($owner: String) {
    onDeleteReport(owner: $owner) {
      id
      owner
      venueID
      reportUri
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAvailability = /* GraphQL */ `
  subscription OnCreateAvailability($owner: String) {
    onCreateAvailability(owner: $owner) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateAvailability = /* GraphQL */ `
  subscription OnUpdateAvailability($owner: String) {
    onUpdateAvailability(owner: $owner) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteAvailability = /* GraphQL */ `
  subscription OnDeleteAvailability($owner: String) {
    onDeleteAvailability(owner: $owner) {
      id
      owner
      businessID
      name
      description
      active
      recurrentDays
      startDate
      endDate
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exceptions {
        items {
          id
          owner
          availabilityID
          name
          description
          type
          date
          startTime
          duration
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateAvailabilityException = /* GraphQL */ `
  subscription OnCreateAvailabilityException($owner: String) {
    onCreateAvailabilityException(owner: $owner) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAvailabilityException = /* GraphQL */ `
  subscription OnUpdateAvailabilityException($owner: String) {
    onUpdateAvailabilityException(owner: $owner) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAvailabilityException = /* GraphQL */ `
  subscription OnDeleteAvailabilityException($owner: String) {
    onDeleteAvailabilityException(owner: $owner) {
      id
      owner
      availabilityID
      name
      description
      type
      date
      startTime
      duration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateService = /* GraphQL */ `
  subscription OnCreateService($owner: String) {
    onCreateService(owner: $owner) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const onUpdateService = /* GraphQL */ `
  subscription OnUpdateService($owner: String) {
    onUpdateService(owner: $owner) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const onDeleteService = /* GraphQL */ `
  subscription OnDeleteService($owner: String) {
    onDeleteService(owner: $owner) {
      id
      owner
      businessID
      name
      description
      duration
      price
      active
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($owner: String) {
    onCreateCategory(owner: $owner) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($owner: String) {
    onUpdateCategory(owner: $owner) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($owner: String) {
    onDeleteCategory(owner: $owner) {
      id
      owner
      title
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking($owner: String) {
    onCreateBooking(owner: $owner) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($owner: String) {
    onUpdateBooking(owner: $owner) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking($owner: String) {
    onDeleteBooking(owner: $owner) {
      id
      owner
      businessID
      customerID
      serviceID
      price
      status
      createdAt
      chargeID
      note
      locationName
      date
      time
      duration
      _version
      _deleted
      _lastChangedAt
      updatedAt
      customer {
        id
        userID
        universityID
        studentID
        cartID
        cbordPaymentToken
        firstName
        lastName
        phoneNumber
        image_url
        image {
          bucket
          region
          key
        }
        readGroups
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        cart {
          id
          owner
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      business {
        id
        universityID
        providerID
        owner
        name
        description
        locationOptionOnSite
        locationOptionVisit
        shortLocation
        telephone
        email
        links
        status
        image_url
        image {
          bucket
          region
          key
        }
        categories
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        provider {
          id
          userID
          universityID
          firstName
          lastName
          phoneNumber
          schoolMajor
          schoolYear
          schoolOrganizations
          links
          image_url
          image {
            bucket
            region
            key
          }
          status
          invitationalEmail
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        university {
          id
          owner
          name
          description
          domain
          cbordUniversity
          cbordEnabled
          utcOffset
          image_url
          image {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          businesses {
            items {
              id
              universityID
              providerID
              owner
              name
              description
              locationOptionOnSite
              locationOptionVisit
              shortLocation
              telephone
              email
              links
              status
              image_url
              categories
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          venues {
            items {
              id
              universityID
              owner
              name
              businessName
              description
              status
              type
              categoty
              buffetPrice
              image_url
              payInApp
              payInPerson
              payWithCard
              payWithCash
              payWithStudentID
              payWithCurrency
              currency
              dineInAvailable
              reservationAvailable
              pickUpAvailable
              deliveryAvailable
              timeSlotsDineInCapacity
              timeSlotsPickUpCapacity
              timeSlotsDeliveryCapacity
              timeslotsIgnore
              timeslotsInterval
              timeSlotsStatus
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
      service {
        id
        owner
        businessID
        name
        description
        duration
        price
        active
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        business {
          id
          universityID
          providerID
          owner
          name
          description
          locationOptionOnSite
          locationOptionVisit
          shortLocation
          telephone
          email
          links
          status
          image_url
          image {
            bucket
            region
            key
          }
          categories
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          provider {
            id
            userID
            universityID
            firstName
            lastName
            phoneNumber
            schoolMajor
            schoolYear
            schoolOrganizations
            links
            image_url
            image {
              bucket
              region
              key
            }
            status
            invitationalEmail
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            university {
              id
              owner
              name
              description
              domain
              cbordUniversity
              cbordEnabled
              utcOffset
              image_url
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            businesses {
              nextToken
              startedAt
            }
          }
          university {
            id
            owner
            name
            description
            domain
            cbordUniversity
            cbordEnabled
            utcOffset
            image_url
            image {
              bucket
              region
              key
            }
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            businesses {
              nextToken
              startedAt
            }
            venues {
              nextToken
              startedAt
            }
          }
        }
      }
    }
  }
`;
